'use client';
import { Suspense, type JSX } from 'react';
import { Button, Feedback } from 'geist/components';
import { ButtonLink } from '@pyra/multi-zone/link';
import { tid } from '@vercel/geist-test-utils';
import {
  analytics,
  AnalyticsEvent,
} from '@vercel/site-analytics/vercel-client';
import { useUser } from '@pyra/vercel-api/hooks/use-user';
import { AvatarPopover } from '#/app/components/layout/avatar-popover';
import styles from './index.module.css';

export function AvatarWrapper(): JSX.Element {
  const { data: userData } = useUser();
  return (
    <Suspense fallback={<AvatarFallback />}>
      {userData ? (
        <UserBasedView hasUser={!userData.user} />
      ) : (
        <AvatarFallback />
      )}
    </Suspense>
  );
}

function UserBasedView({ hasUser }: { hasUser: boolean }): JSX.Element {
  return (
    <>
      {hasUser ? (
        <div className={styles.avatarWrapper}>
          <div className={styles.feedback}>
            <Feedback label="docs" showEmail />
          </div>
          <div className={styles.loginBtn}>
            <ButtonLink
              data-testid={tid('header', 'login')}
              href="/login"
              onClick={() => {
                analytics.track(AnalyticsEvent.CLICK_EVENT, {
                  click_name: 'docs_nav',
                  click_value: 'log in',
                });
              }}
              size="small"
              type="secondary"
            >
              Log In
            </ButtonLink>
          </div>
          <ButtonLink
            data-testid={tid('header', 'signup')}
            href="/signup"
            onClick={() => {
              analytics.track(AnalyticsEvent.CLICK_EVENT, {
                click_name: 'docs_nav',
                click_value: 'sign up',
              });
            }}
            size="small"
          >
            Sign Up
          </ButtonLink>
        </div>
      ) : (
        <div className={styles.avatarWrapper}>
          <div className={styles.feedback}>
            <Feedback label="docs" showEmail />
          </div>
          <AvatarPopover />
        </div>
      )}
    </>
  );
}

function AvatarFallback(): JSX.Element {
  return (
    <div className={styles.avatarWrapper}>
      <div className={styles.feedback}>
        <Button size="small" type="secondary">
          Feedback
        </Button>
      </div>
      <div className={styles.placeholder} />
    </div>
  );
}
