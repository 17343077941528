'use client';
import dynamic from 'next/dynamic';

const DocsCommandMenu = dynamic(() =>
  import('@pyra/command-menu').then((mod) => mod.DocsCommandMenu),
);

// eslint-disable-next-line import/no-default-export
export default function CommandMenu(): JSX.Element | null {
  return <DocsCommandMenu />;
}
