'use client';

import { createContext, useContext, useMemo, type JSX } from 'react';
import * as NavigationMenuPrimitive from '@radix-ui/react-navigation-menu';
import { usePathname } from 'next/navigation';
import type { ReactNode } from 'react';
import { Link } from '@pyra/multi-zone/link';
import type { NavigationMenuItem } from './types';
import styles from './top-navigation.module.css';

interface NavigationMenuContextT {
  activeHref?: string;
  isSecondary?: boolean;
}

export interface NavigationMenuProps
  extends NavigationMenuPrimitive.NavigationMenuProps {
  open?: boolean;
  data: NavigationMenuItem[];
  isSecondary?: boolean;
}

const NavigationMenuContext = createContext<NavigationMenuContextT>(
  {} as NavigationMenuContextT,
);

export const useNavigationMenuContext = (): NavigationMenuContextT =>
  useContext(NavigationMenuContext);

export function TopNavigation({ data = [] }: NavigationMenuProps): JSX.Element {
  const path = usePathname() as string;
  const activeHref = path;
  const providerValues = useMemo(() => ({ activeHref }), [activeHref]);
  return (
    <NavigationMenuPrimitive.Root
      aria-label="Navigation header links"
      className={styles.wrapper}
      delayDuration={0}
    >
      <NavigationMenuContext.Provider value={providerValues}>
        <NavigationMenuPrimitive.List className={styles.list}>
          {data.map((dataItem) => (
            <MenuLink href={dataItem.href} key={dataItem.name}>
              {dataItem.name}
            </MenuLink>
          ))}
        </NavigationMenuPrimitive.List>
      </NavigationMenuContext.Provider>
    </NavigationMenuPrimitive.Root>
  );
}

function MenuLink({
  children,
  href,
}: {
  children: ReactNode;
  href: string;
}): JSX.Element {
  const { activeHref, isSecondary } = useNavigationMenuContext();
  const isActive = isSecondary
    ? activeHref === href
    : activeHref?.startsWith(href);
  return (
    <NavigationMenuPrimitive.Item>
      <Link className={styles.link} data-active={isActive} href={href}>
        {children}
      </Link>
    </NavigationMenuPrimitive.Item>
  );
}
