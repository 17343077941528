import type { MobileMenuProps } from '@pyra/vercel-header/mobile-menu';
import {
  CONTACT,
  LOGIN,
  SIGN_UP,
  LOGGED_IN_ITEMS,
  DASHBOARD,
  HOMEPAGE,
} from '@pyra/vercel-header/mobile-menu/items';

export const DOCS_LOGGED_OUT = (authNext?: string): MobileMenuProps => ({
  navigation: [
    {
      name: 'Documentation',
      href: '/docs',
    },
    {
      name: 'Guides',
      href: '/guides',
    },
    {
      name: 'Help',
      href: '/help',
    },
  ],
  userItems: [HOMEPAGE, LOGIN(authNext)],
  ctas: {
    primary: SIGN_UP(authNext),
    secondary: CONTACT,
  },
  order: ['navigation', 'user', 'ctas'],
});

export const DOCS_LOGGED_IN: MobileMenuProps = {
  ...DOCS_LOGGED_OUT(),
  userItems: [DASHBOARD, ...LOGGED_IN_ITEMS(), HOMEPAGE],
  ctas: {
    secondary: CONTACT,
  },
};
