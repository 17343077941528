'use client';
import { usePathname } from 'next/navigation';
import type { JSX } from 'react';
import { getSecondaryNavigation } from '@pyra/docs-shared/data/navigation/utils';
import { primaryNavigation } from '@pyra/docs-shared/data/navigation/primary';
import { SecondaryNavigation } from './secondary-navigation';
import styles from './index.module.css';

export function SecondaryNavWrapper(): JSX.Element {
  const path = usePathname() as string;
  const secondaryNavigation = getSecondaryNavigation(primaryNavigation, path);
  return (
    <div className={styles.secondaryNavContainer}>
      <SecondaryNavigation
        activeHref={secondaryNavigation.currentItem?.href}
        data={secondaryNavigation.navItems}
        extraItems={
          secondaryNavigation.extraItems ? secondaryNavigation.extraItems : []
        }
      />
    </div>
  );
}
