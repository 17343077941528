'use client';

import { LazyAuthNext } from '@pyra/vercel-header/lazy-auth-next';
import type { MobileMenuProps as PyraMobileMenuProps } from '@pyra/vercel-header/mobile-menu';
import { MobileMenu as PyraMobileMenu } from '@pyra/vercel-header/mobile-menu';
import { Suspense, useMemo, useState } from 'react';
import { useUser } from '@pyra/vercel-api/hooks/use-user';
import { DOCS_LOGGED_IN, DOCS_LOGGED_OUT } from './config';

interface MobileMenuProps {
  breakpoint?: number;
}

export function MobileMenu({ breakpoint = 950 }: MobileMenuProps): JSX.Element {
  const { user } = useUser();
  const loggedIn = Boolean(user);

  const [authNext, setAuthNext] = useState<string>('');

  const props: PyraMobileMenuProps = useMemo(() => {
    return loggedIn ? DOCS_LOGGED_IN : DOCS_LOGGED_OUT(authNext);
  }, [loggedIn, authNext]);

  return (
    <>
      <Suspense>
        <LazyAuthNext setNext={setAuthNext} />
      </Suspense>
      <PyraMobileMenu {...props} breakpoint={breakpoint} />
    </>
  );
}
