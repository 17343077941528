'use client';

import { clsx } from 'clsx';
import { useEffect, useState, type JSX } from 'react';
import { awaitPaint } from 'geist/tmp/utils';
import { MobileMenu } from '#/app/components/layout/mobile-menu';
import { CmdkLaunch } from './cmdk-launch';
import { AvatarWrapper } from './avatar-wrapper';
import styles from './index.module.css';

export function HeaderRight(): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    void awaitPaint().then(() => setLoaded(true));
  }, []);
  return (
    <div className={clsx(styles.rightSection, loaded ? styles.show : '')}>
      <div className={styles.searchContainer}>
        <CmdkLaunch />
      </div>
      <div className={styles.avatarContainer}>
        <AvatarWrapper />
      </div>
      <MobileMenu breakpoint={950} />
    </div>
  );
}
