'use client';

import { AvatarPopover as PyraAvatarPopover } from '@pyra/vercel-header/avatar-popover';
import type { UserForAvatar } from '@pyra/vercel-header/src/avatar-popover/types';

interface AvatarPopoverProps {
  breakpoint?: number;
  initialUser?: UserForAvatar;
}

export function AvatarPopover({
  initialUser,
  breakpoint = 1150,
}: AvatarPopoverProps): JSX.Element | null {
  return (
    <PyraAvatarPopover breakpoint={breakpoint} initialUser={initialUser} />
  );
}
