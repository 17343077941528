/* eslint-disable react/jsx-pascal-case */
'use client';
import * as React from 'react';
import { Search as IconSearch } from 'geist/icons';
import { Stack, Kbd as KBD } from 'geist/components';
import { isAppleDevice } from '@react-aria/utils';
import { awaitPaint } from '@pyra/components/rendering/await-paint';
import type { JSX } from 'react';
import styles from './cmdklaunch.module.css';

export function CmdkLaunch(): JSX.Element {
  const triggerMouseCmdk = (): void => {
    window.dispatchEvent(
      new KeyboardEvent('keydown', {
        key: 'k',
        ...(isAppleDevice() ? { metaKey: true } : { ctrlKey: true }),
      }),
    );
  };

  const triggerKBDCmdk = (evt: React.KeyboardEvent): void => {
    if (evt.key !== 'Tab') {
      window.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'k',
          ...(isAppleDevice() ? { metaKey: true } : { ctrlKey: true }),
        }),
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <button
        className={styles.launcher}
        onClick={(): void => void awaitPaint().then(() => triggerMouseCmdk())}
        onKeyDown={(evt): void =>
          void awaitPaint().then(() => triggerKBDCmdk(evt))
        }
        type="button"
      >
        <Stack align="center" direction="row" gap={3}>
          <IconSearch
            className={styles.inputIcon}
            color="var(--accents-5)"
            size={18}
          />
          <span className={styles.placeholder}>Search...</span>
        </Stack>
        <Stack align="center" className={styles.rightIcon} direction="row">
          <KBD className={styles.keyboard} small>
            ⌘ K
          </KBD>
        </Stack>
      </button>
    </div>
  );
}
/* eslint-enable react/jsx-pascal-case */
